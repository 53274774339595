<div class="user-card">
  <div class="row row-cols-sm-2 gy-2 py-3">
    <span *ngIf="rowNumber" class="card-counter"> {{ rowNumber }} </span>
    <div *ngFor="let item of mainData; let i = index">
      <div *ngIf="item.field !== 'status' && item.field !== 'link' && item.field !== 'select' && item.type !== 'editable'" class="">
        <label class="fw-bold"> {{ item.title | translate }} </label> : <span [class]="item.class"> {{ item.text }} </span>
      </div>
      <div *ngIf="item.field === 'check' || item.type === 'check'" class="align-items-center gap-2 d-flex">
        <input (change)="onDropdownItemClick('check')" type="checkbox" [(ngModel)]="content[item.field]" [id]="item.field + i" class="form-check-input" />
        <label [for]="item.field + i" class="mb-0"> {{ item.title | translate }} </label>
      </div>
      <div *ngIf="item.field === 'link' && link" [innerHtml]="link" class="w-100"> </div>
      <div *ngIf="item.type === 'editable'" class="align-items-center gap-2 d-flex">
        <price-input *ngIf="item.field && item.field.includes('Amount')" [(value)]="content[item.field]" [label]="item.title"> </price-input>
      </div>
    </div>
    <app-dropdown *ngIf="selectItems.length" (valueChange)="onDropdownItemClick('select')" [items]="selectItems" [(value)]="content['status']" label="STATUS"> </app-dropdown>
  </div>
  <div *ngIf="showExtraData && extraData && extraData.length" [@slideDownUp] class="overflow-hidden">
    <div class="row row-cols-sm-2 gy-2">
      <div *ngFor="let item of extraData">
        <ng-container *ngIf="item.field !== 'status' && item.field !== 'link' && item.field !== 'select' && item.type !== 'editable'">
          <label *ngIf="item.title !== 'STATUS'" class="fw-bold"> {{ item.title | translate }} </label> : <span [class]="item.class"> {{ item.text }} </span>
        </ng-container>
        <div *ngIf="item.field === 'link' && link" [innerHtml]="link" class="w-100"> </div>
      </div>
    </div>
  </div>
  <div class="align-items-center justify-content-between gap-md-3 flex-column flex-md-row d-flex">
    <div *ngIf="status" [innerHtml]="status" class="w-100 mt-3"> </div>
    <div *ngIf="dropdownItems && dropdownItems.length" ngbDropdown class="w-100 mt-3">
      <app-btn-custom ngbDropdownToggle (onClick)="isShadow = true" type="button" [theme]="{ type: 'solid', color: 'blue', size: 'lg', text: 'ACTIONS' }"
                      classList="w-100" class="d-block"> </app-btn-custom>
      <div ngbDropdownMenu>
        <button *ngFor="let item of dropdownItems" ngbDropdownItem (click)="isShadow = false; onDropdownItemClick(item)" [style.max-height.px]="35"
                class="align-items-center d-flex"> {{ item | translate }} </button>
      </div>
    </div>
    <app-btn-custom *ngIf="extraData && extraData.length" (onClick)="showExtraData = !showExtraData" type="button"
                    [theme]="{ type: 'fill', color: 'blue', size: 'lg', text: 'SHOW_' + (showExtraData ? 'LESS' : 'MORE') }"
                    classList="w-100" class="w-100 d-block mt-3"> </app-btn-custom>
  </div>
</div>
<footer *ngIf="showFooter && ((showTotal && total) || paging)" [class]="(showTotal && total && paging ? 'justify-content-between' : 'justify-content-end') + ' d-flex'" style="margin: 0 -0.75rem">
  <div *ngIf="showTotal && total" class="page-link border-0 py-0">
    <span> {{ 'TOTAL_COUNT' | translate }} : {{ total }} </span>
  </div>
  <ngb-pagination *ngIf="paging" (pageChange)="onChangePaging($event)" [page]="(paging.skip / paging.take) + 1" [pageSize]="paging.take" [maxSize]="5" [collectionSize]="total"
                  class="pagination pagination-rounded justify-content-end"> </ngb-pagination>
</footer>
<div *ngIf="isShadow" (click)="isShadow = false" class="shadow-overlay"> </div>
