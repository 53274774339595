import { Component, OnInit, Output, EventEmitter, Inject } from "@angular/core";
import { Router } from "@angular/router";
import { DOCUMENT } from "@angular/common";
import { AuthenticationService } from "../../core/services/auth.service";
import { AuthfakeauthenticationService } from "../../core/services/authfake.service";
import { environment } from "../../../environments/environment";
import { CookieService } from "ngx-cookie-service";
import { LanguageService } from "../../core/services/language.service";
import { TranslateService } from "@ngx-translate/core";
import { ToolsService } from "src/app/core/services/tools.service";
import { ApiService } from "src/app/core/services/api.service";
import { UserModel } from "src/app/core/models/public-model";
import { BranchModel } from "src/app/core/models/donation-financial-model";
import { of } from "rxjs";
import { catchError, map, concatMap } from "rxjs/operators";
import { PermissionModel } from "../../core/models/auth.models";
import { Roles } from "../../core/models/enums";

interface LangModel {
  id?: number;
  text?: string;
  flag?: string;
  lang?: string;
}

@Component({
  selector: "app-topbar",
  templateUrl: "./topbar.component.html",
  styleUrls: ["./topbar.component.scss"],
})

/**
 * Topbar component
 */
export class TopbarComponent implements OnInit {
  element;
  cookieValue;
  flagvalue;
  countryName;
  valueset;
  user: UserModel;

  constructor(
    @Inject(DOCUMENT) private document: any,
    private router: Router,
    private authService: AuthenticationService,
    private authFackservice: AuthfakeauthenticationService,
    public languageService: LanguageService,
    public translate: TranslateService,
    public _cookiesService: CookieService,
    public tools: ToolsService,
    private api: ApiService
  ) {}

  langList = [
    // { id: 1, text: "English", flag: "assets/images/flags/us.jpg", lang: "en" },
    { id: 2, text: "العربی", flag: "assets/images/flags/iraq.png", lang: "ar" },
    { id: 3, text: "فارسی", flag: "assets/images/flags/iran.png", lang: "ir" },
    // { text: 'Spanish', flag: 'assets/images/flags/spain.jpg', lang: 'es' },
    // { text: 'German', flag: 'assets/images/flags/germany.jpg', lang: 'de' },
    // { text: 'Italian', flag: 'assets/images/flags/italy.jpg', lang: 'it' },
    // { text: 'Russian', flag: 'assets/images/flags/russia.jpg', lang: 'ru' },
  ];
  selectedLang: LangModel;
  env = environment.api;
  openMobileMenu: boolean;
  branches: BranchModel[];
  selectedBranch: BranchModel;
  isAdmin = this.tools.getUserRoles().indexOf(Roles.ADMIN.toString()) !== -1;

  @Output() settingsButtonClicked = new EventEmitter();
  @Output() mobileMenuButtonClicked = new EventEmitter();

  ngOnInit() {
    this.branches = this.tools.getBranches();
    this.selectedBranch = this.tools.getSelectedBranch();
    this.openMobileMenu = false;
    this.element = document.documentElement;
    this.cookieValue = this._cookiesService.get("lang");
    const val = this.langList.filter((x) => x.lang === this.cookieValue);
    this.countryName = val.map((element) => element.text);
    if (val.length === 0) {
      if (this.flagvalue === undefined) {
        this.valueset = "assets/images/flags/us.jpg";
      }
    } else {
      this.flagvalue = val.map((element) => element.flag);
    }

    const langId = +localStorage.getItem("langId");
    if (langId) {
      this.selectedLang = this.langList.find((l) => l.id === langId);
    } else {
      this.selectedLang = this.langList[0];
      localStorage.setItem("langId", this.selectedLang.id.toString());
    }

    this.user = this.tools.getUser();
    // this.getBranches();
  }

  setLanguage(item: LangModel) {
    // this.countryName = text;
    // this.flagvalue = flag;
    // this.cookieValue = lang;
    // this.languageService.setLanguage(lang);
    if (item.id === this.selectedLang.id) {
      return;
    }
    localStorage.setItem("langId", item.id.toString());
    location.reload();
  }

  // getBranches() {
  //   this.branches$ = this.api
  //     .post<any[]>({}, "/v3/Branch/FindBranch", "accounting")
  //     .pipe(map((m) => m.data));
  // }

  // setBranch(event) {
  //   localStorage.setItem("branch", event.id);
  // }

  /**
   * Toggles the right sidebar
   */
  toggleRightSidebar() {
    this.settingsButtonClicked.emit();
  }

  /**
   * Toggle the menu bar when having mobile screen
   */
  toggleMobileMenu(event: any) {
    event.preventDefault();
    this.tools.setColWidth();
    this.mobileMenuButtonClicked.emit();
  }

  /**
   * Logout the user
   */
  logout() {
    if (environment.defaultAuth === "firebase") {
      this.authService.logout();
    } else {
      this.authFackservice.logout();
    }
    this.router.navigate(["/account/login"]);
  }

  /**
   * Fullscreen method
   */
  fullscreen() {
    document.body.classList.toggle("fullscreen-enable");
    if (
      !document.fullscreenElement &&
      !this.element.mozFullScreenElement &&
      !this.element.webkitFullscreenElement
    ) {
      if (this.element.requestFullscreen) {
        this.element.requestFullscreen();
      } else if (this.element.mozRequestFullScreen) {
        /* Firefox */
        this.element.mozRequestFullScreen();
      } else if (this.element.webkitRequestFullscreen) {
        /* Chrome, Safari and Opera */
        this.element.webkitRequestFullscreen();
      } else if (this.element.msRequestFullscreen) {
        /* IE/Edge */
        this.element.msRequestFullscreen();
      }
    } else {
      if (this.document.exitFullscreen) {
        this.document.exitFullscreen();
      } else if (this.document.mozCancelFullScreen) {
        /* Firefox */
        this.document.mozCancelFullScreen();
      } else if (this.document.webkitExitFullscreen) {
        /* Chrome, Safari and Opera */
        this.document.webkitExitFullscreen();
      } else if (this.document.msExitFullscreen) {
        /* IE/Edge */
        this.document.msExitFullscreen();
      }
    }
  }

  getBranches() {
    // this.loading = true;
    return this.api.post<BranchModel[]>({}, '/v3/Branch/FindBranch', 'accounting').pipe(map((res) => {
      res.data = res.data.sort((a, b) => a.order < b.order ? -1 : a.order > b.order ? 1 : 0);
      return res.data;
    }), concatMap((data) => {
      if (this.tools.getUserRoles().indexOf(Roles.ADMIN.toString()) === -1) {
        this.tools.setBranches(data);
        return of(data);
      }
      return this.api.post<any[]>({ userIdentifier: this.tools.getUserID() }, '/User/GetUserBranches', 'account').pipe(map((res) => {
        if (res.data && res.data.length) {
          const branches: number[] = [];
          res.data.map((item) => branches.push(item.id));
          data = data.filter((item) => branches.includes(item.id));
          this.tools.setBranches(data);
        }
        return data;
      }));
    }), catchError(() => {
      this.tools.setBranches([]);
      this.tools.setSelectedBranch({}, 'user');
      // this.loading = false;
      return of([]);
    }));
  }

  getUserById() {
    // this.userLoading = true;
    const userIdentifier = this.tools.getUserID();
    return this.api.get<UserModel>({}, '/User/GetByIdentifier/' + userIdentifier, 'account').pipe(map((res) => {
      return res.data;
    }), concatMap((data) => {
      return this.api.post({ userIdentifier }, '/HR/Employee/GetOrganizationalPosition').pipe(map((res: any) => {
        this.tools.setUser({ ...data, departmentId: res.data && res.data.length ? res.data[0].departmentId : null });
        const branches = this.tools.getBranches();
        if (res.data && res.data.length) {
          const isCentral = branches.find((item) => item.id === res.data[0].branchId).isCentral;
          const temp = branches.find((item) => item.id === (+localStorage.getItem('branchId') || res.data[0].branchId));
          temp.isCentral = isCentral;
          this.tools.setSelectedBranch(temp, 'user');
        }
      }), catchError(() => {
        const user: UserModel = {};
        // this.loading = this.userLoading = false;
        return of(user);
      }));
    }), catchError(() => {
      const user: UserModel = {};
      // this.loading = this.userLoading = false;
      return of(user);
    }));
  }

  getPermissions() {
    // this.permissionLoading = true;
    return this.api.get<PermissionModel[]>({}, `/User/GetAllPermission?UserIdentifier=${ this.tools.getUserID() }&BranchId=${ this.tools.getSelectedBranch()?.id }`, 'account').pipe(map((res) => {
      this.tools.setPermissions(res.data);
      // this.loading = this.userLoading = this.permissionLoading = false;
    }), catchError(() => {
      this.tools.setPermissions([]);
      // this.loading = this.userLoading = this.permissionLoading = false;
      return of([]);
    }));
  }

  reload (): void {
    this.getBranches().pipe(concatMap(() => this.getUserById().pipe(concatMap(() => this.getPermissions().pipe(map(() => {
      window.location.reload();
    })))))).subscribe();
  }
}
