import {
  Component,
  OnInit,
  AfterViewInit,
  OnDestroy,
  Input,
  Output,
  EventEmitter,
  ViewChild,
  ElementRef,
} from "@angular/core";
import { fromEvent, Subscription } from "rxjs";
import { map, debounceTime, distinctUntilChanged } from "rxjs/operators";
import { ToolsService } from "../../../core/services/tools.service";

@Component({
  selector: "search-field",
  templateUrl: "./search-field.component.html",
  styleUrls: ["./search-field.component.scss"],
})
export class SearchFieldComponent implements OnInit, AfterViewInit, OnDestroy {
  subscription: Subscription;
  @Input() placeholder = "SEARCH";
  @Input() dropdownItems = [];
  @Input() dropdownItemSelected = this.dropdownItems[0];
  @Input() dropdownDisabled = false;
  @Output() onSearch = new EventEmitter();
  @Output() dropdownItemClicked = new EventEmitter();
  @Output() dropdownItemSelectedChange = new EventEmitter();
  @ViewChild("searchInput") searchInput: ElementRef;

  constructor(public toolsService: ToolsService) { }

  ngOnInit(): void {}

  ngAfterViewInit(): void {
    this.subscription = fromEvent(this.searchInput.nativeElement, "keyup")
      .pipe(
        debounceTime(500),
        map((event) => event as KeyboardEvent),
        map((event) => (event.target as HTMLInputElement).value),
        distinctUntilChanged(),
        map((res) => this.onSearch.emit(res))
      )
      .subscribe();
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  itemClicked(item): void {
    if (item.id !== this.dropdownItemSelected.id) {
      this.dropdownItemSelectedChange.emit(item);
    }
    this.dropdownItemSelected = item;
    this.dropdownItemClicked.emit(item);
  }
}
