import { Component } from '@angular/core';
import { IHeaderAngularComp } from 'ag-grid-angular';
import { IHeaderParams } from 'ag-grid-community';

@Component({
  selector: 'app-custom-header',
  template: `
    <ng-container *ngIf='params["data"] && params["data"].length'>
      <div *ngFor='let item of params["data"]'> {{ (item | translate) + (params["symbol"] ? " (" + (params["symbol"] | translate) + ")" : "") }} </div>
    </ng-container>
    <i *ngIf='params["icon"]' [class]='"mdi " + params["icon"] + " font-size-24"'> </i>
  `
})

export class CustomHeader implements IHeaderAngularComp {

  public params: IHeaderParams;

  agInit(params: IHeaderParams): void {
    this.params = params;
  }

  refresh(params: IHeaderParams) {
    return true;
  }

}
