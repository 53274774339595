<div [class.is-loading-api]="toolsService.isLoadingApi" class="search-section">
  <i class="icon icon-search ms-2"> </i>
  <input #searchInput type="text" [placeholder]="placeholder | translate" class="form-control" [disabled]="toolsService.isLoadingApi" />
  <div *ngIf="dropdownItems.length">
    <div ngbDropdown placement="bottom-right" class="dropdown-menu-end d-inline-block">
      <button ngbDropdownToggle type="button" class="btn btn-filter" [disabled]="dropdownDisabled">
        <span class="value-selected"> {{ (dropdownItemSelected?.name || dropdownItemSelected) | translate }} </span>
        <i class="icon icon-arrow-down mt-1 mx-0"> </i>
      </button>
      <div ngbDropdownMenu>
        <button *ngFor="let item of dropdownItems" type="button" ngbDropdownItem (click)="itemClicked(item)"> {{ item.name | translate }} </button>
      </div>
    </div>
  </div>
</div>
