import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-confirm-modal',
  templateUrl: 'confirm-modal.component.html',
  styleUrls: ['confirm-modal.component.scss']
})

export class ConfirmModalComponent implements OnInit {

  @Input() id: string;
  @Input() title: string;
  @Input() text: string;
  @Input() text2: string;
  @Input() color: string;
  @Input() secondColor: string;
  @Input() primaryText: string;
  @Input() secondText: string;
  @Input() isQuestion: boolean;
  @Input() isInfo: boolean;
  @Input() modalRef: any;
  @Output() confirm = new EventEmitter();

  constructor(public modalService: NgbModal) {
    this.title = 'ARE_YOU_SURE';
    this.text = 'YOU_WONT_BEABLE_TO_REVERT_THIS';
    this.text2 = '';
    this.color = 'blue';
    this.secondColor = this.color;
    this.primaryText = 'YES';
    this.secondText = 'NO';
    this.isQuestion = true;
    this.isInfo = false;
  }

  ngOnInit(): void { }

}
