import { DatePipe } from "@angular/common";
import {
  Component,
  EventEmitter,
  HostListener,
  Input,
  OnInit,
  Output,
  SimpleChanges,
} from "@angular/core";
import {
  ColDef,
  ColumnApi,
  GridApi,
  GridReadyEvent,
  RowClassRules,
  SortChangedEvent,
} from "ag-grid-community";
import { PagingModel } from "src/app/core/models/paging.model";
import { TranslatePipe } from "src/app/core/pipes/translate.pipe";
import { ToolsService } from "src/app/core/services/tools.service";
import { TotalValueRenderer } from "./renderer/total-value-renderer";

@Component({
  selector: "portal-grid-table",
  templateUrl: "./grid-table.component.html",
  styleUrls: ["./grid-table.component.scss", "grid-table.component.rtl.scss"],
})
export class GridTableComponent implements OnInit {
  @Input() rowClassRules: RowClassRules;
  @Input() hasCRUD = false;
  @Input() columns: any[];
  @Input() data: any;
  @Input() gridHeight: string;
  @Input() gridFixHeight: string;
  @Input() hasCheckbox = false;
  @Input() isAction = false;
  @Input() total: number;
  @Input() loading = false;
  @Input() paging: PagingModel;
  @Input() hasSearch = false;
  @Input() localSearch = false;
  @Input() clientSide = false;
  @Input() gridFit = false;
  @Input() dynamicColWidth = false;
  @Input() searchSlotClass = ''
  @Input() searchItems: Array<{ id?: number; name?: string; value?: string }> =
    [];
  @Input() showTotal = true;
  @Output() addClick: EventEmitter<any> = new EventEmitter();
  @Output() actionClick: EventEmitter<any> = new EventEmitter();
  @Output() gridReady: EventEmitter<any> = new EventEmitter();
  @Output() cellClick = new EventEmitter();
  @Output() selectedChange: EventEmitter<any> = new EventEmitter();
  @Output() pageChange: EventEmitter<any> = new EventEmitter();
  @Output() onCellEdited: EventEmitter<any> = new EventEmitter();
  @Output() search = new EventEmitter();

  localData = [];
  clientSideData = [];
  dropdownItemSelected: { id?: number; name?: string; value?: string };
  isAdmin = false;
  defaultColDef: ColDef = {
    minWidth: 100,
    autoHeight: true,
    cellClass: "ag-cell-value-custom",
    wrapText: true,
    editable: false,
    sortable: false,
    flex: 1,
    filter: false,
    resizable: true,
  };
  columnDefs: ColDef[] = [];
  selectedRows: any[] = [];
  private gridApi!: GridApi;
  private gridColumnApi!: ColumnApi;
  autoGroupColumnDef: ColDef;
  context: any;
  page = 1;
  rtl = true; // set RTL
  searchTerm: string;

  constructor(
    private tools: ToolsService,
    private translatePipe: TranslatePipe
  ) {
    this.context = { componentParent: this };
  }

  ngOnInit(): void {
    this.dropdownItemSelected = this.searchItems[0];
    if (this.columns && this.columns.length) {
      if (this.hasCheckbox) {
        this.columnDefs.push({
          headerName: "",
          width: 70,
          checkboxSelection: true,
        });
      }

      this.columns.map((col) => (col.resizable = !col.pinned));

      if (this.localSearch && this.data) {
        this.localData = [...this.data];
      }

      if (this.isAction) {
        this.columnDefs.push({
          field: "id",
          headerName: "Actions",
          minWidth: 175,
          cellRenderer: TotalValueRenderer,
        });
      }
    }

    this.getAllRows();

    this.columnDefs.map((c) => {
      const cField = c.field ? c.field.toLowerCase() : '';
      const cHeaderName = c.headerName ? c.headerName.toLowerCase() : '';
      c.headerName = this.translatePipe.transform(c.headerName);
      if (c.headerName === "DESCRIPTION" || c.field === "description") {
        c.cellStyle = {
          "text-overflow": "ellipsis",
          "white-space": "nowrap",
          overflow: "hidden",
          padding: 0,
        };
      }
      const conditionIQD = (cField.includes('amount') || cHeaderName.includes('amount') || (cField.includes('total') && c.cellRenderer)) && !cHeaderName.includes('_ar') && !cHeaderName.includes(this.translatePipe.transform('IQD'));
      c.headerName += conditionIQD ? (' (' + this.translatePipe.transform('IQD') + ')') : '';
      return c;
    });

    const temp = this.columnDefs.find((c) => c.headerName === "#");
    temp ? temp.suppressSizeToFit = true : null;
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.columnDefs = this.columns;
    this.columnDefs.map(
      (item) =>
        (item.valueGetter =
          item.headerName === "#"
            ? `node.rowIndex + ${this.paging?.skip ? this.paging.skip + 1 : 1}`
            : "")
    );
    this.columnDefs = this.columnDefs.slice();

    if (this.clientSide && changes.data && this.data.length) {
      this.data.map((d, i) => (d.rowNumber = i + 1));
      const temp = this.columnDefs.find((c) => c.headerName === "#");
      temp.field = "rowNumber";
      temp.valueGetter = null;
      temp.suppressSizeToFit = true;
      if (this.localSearch) {
        this.localData = [...this.data];
      }
      this.clientSideData = [...this.data];
      this.data = this.clientSideData.slice(0, this.paging.take);
    }

    if (
      changes.data &&
      changes.data.currentValue &&
      changes.data.currentValue["data"]
    ) {
      let datePipe = new DatePipe("en-US");
      changes.data.currentValue["data"].forEach((item) => {
        if (item && item.createDate) {
          item.createDate = datePipe.transform(
            item.createDate,
            "yyyy-MM-dd hh:mm:ss"
          );
        }
      });
    }

    if (this.data?.length >= 0) {
      const temp = this.columnDefs.filter((c) => c["translate"] === true);
      if (temp) {
        this.data.map((d) => {
          temp.forEach(
            (t) => (d[t.field] = this.translatePipe.transform(d[t.field]))
          );
          return d;
        });
      }
      this.tools.setColWidth({
        gridApi: this.gridApi,
        gridColumnApi: this.gridColumnApi,
        gridFit: this.gridFit,
      });

      if (this.localSearch) {
        this.localData = [...this.data];
      }
    }
  }

  @HostListener("window:resize", ["$event"]) onResize() {
    this.tools.setColWidth({
      gridApi: this.gridApi,
      gridColumnApi: this.gridColumnApi,
      gridFit: this.gridFit,
    });
  }

  onCellEdit(event) {
    this.onCellEdited.emit(event);
  }

  onCellValueChange(e) {}

  getAllRows() {
    // this.gridApi.forEachNode((node) => console.log(node));
  }

  actionsGrid(cell: any): void {
    cell["gridApi"] = this.gridApi;
    this.actionClick.emit(cell);
  }

  onGridReady(params: GridReadyEvent): void {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
    this.columnDefs.map((c) => {
      if (c.pinned) {
        c.minWidth = c.width = null;
        c.cellClass = "pe-0";
      }
    });
    this.columnDefs = this.columnDefs.slice();
    if (this.dynamicColWidth) {
      this.removeStaticColWidths();
    }
    this.gridReady.emit(this.gridApi);
    this.tools.setColWidth({
      gridApi: this.gridApi,
      gridColumnApi: this.gridColumnApi,
      gridFit: this.gridFit,
    });
  }

  removeStaticColWidths() {
    this.columnDefs.map((c) => {
      if (c.width&&!c.pinned) {
        c.width = null;
      }
      if (c.minWidth&&!c.pinned) {
        c.minWidth = null;
      }
      return c;
    });
  }

  setColWidth() {
    if (!this.gridColumnApi) {
      return;
    }
    const allColumnIds: string[] = [];
    const cols = this.gridColumnApi?.getAllColumns();
    cols?.forEach((c) => {
      if (c.getColId().includes("description")) {
        return;
      }
      allColumnIds.push(c.getId());
    });

    setTimeout(() => {
      this.gridColumnApi.autoSizeColumns(allColumnIds, false);
    }, 0);

    if (this.gridFit) {
      setTimeout(() => {
        this.gridApi.sizeColumnsToFit();
      }, 0);
    }
  }

  onCellClicked(cell): void {
    this.cellClick.emit(cell);
  }

  onSelectedChange(): void {
    this.selectedRows = this.gridApi.getSelectedRows();
    this.selectedChange.emit(this.selectedRows);
  }

  change(e): void {
    this.page = e;
    this.paging.skip = (e - 1) * this.paging.take;
    if (this.clientSide) {
      this.data = this.clientSideData.slice(
        this.paging.skip,
        this.paging.take + this.paging.skip
      );
    } else {
      this.pageChange.emit(this.paging);
    }
  }

  onSortChange(event: SortChangedEvent) {
    if (!this.paging || (this.paging && !this.paging.sortProperties)) return;
    const sortedCol = event.columnApi
      .getColumnState()
      .find((col) => Boolean(col.sort));
    sortedCol.sort = this.paging.sortProperties[sortedCol.colId] === 0 ? "desc" : "asc";
    switch (sortedCol.sort) {
      case "asc":
        this.paging.sortProperties = { ...this.paging.sortProperties, [sortedCol.colId]: 0 };
        break;
      case "desc":
        this.paging.sortProperties = { ...this.paging.sortProperties, [sortedCol.colId]: 1 };
        break;
      default:
        break;
    }
    this.pageChange.emit(this.paging);
  }

  onSearchItemChange() {
    if (!this.searchTerm) {
      return;
    }
    this.onSearch();
  }

  onSearch() {
    if (this.localSearch) {
      this.searchLocal();
    } else {
      this.search.emit({ [this.dropdownItemSelected.value]: this.searchTerm });
    }
  }

  searchLocal() {
    if (!this.searchTerm) {
      return (this.data = [...this.localData]);
    }
    this.data = this.localData.filter((d) =>
      (d[this.dropdownItemSelected.value] as string)
        ?.toLowerCase()
        ?.includes(this.searchTerm.toLowerCase())
    );
  }
}
