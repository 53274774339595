import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { DatePipe } from '@angular/common';
import { animate, trigger, transition, style } from '@angular/animations';
import { TranslatePipe } from 'src/app/core/pipes/translate.pipe';
import { CellRendererPipe } from 'src/app/core/pipes/cell-renderer.pipe';
import { StatusRenderer } from 'src/app/shared/ui/grid-table/renderer/status-renderer';
import { LinkRenderer } from 'src/app/shared/ui/grid-table/renderer/link-renderer';
import { PagingModel } from 'src/app/core/models/paging.model';

@Component({
  selector: 'app-public-card',
  templateUrl: './public-card.component.html',
  styleUrls: ['./public-card.component.scss', '../grid-table/renderer/grid-table-status.scss'],
  animations: [
    trigger('slideDownUp', [
      transition(':enter', [style({ height: 0 }), animate(300)]),
      transition(':leave', [animate(300, style({ height: 0 }))])
    ])
  ]
})

export class PublicCardComponent implements OnInit {

  mainData: Array<{ type?: string; field?: string; title: string; text: string; class?: string; }>;
  extraData: Array<{ type?: string; field?: string; title: string; text: string; class?: string; }>;
  selectItems: string[];
  dropdownItems: string[];
  showExtraData: boolean;
  page: number;
  isShadow: boolean;
  status: any;
  link: any;
  @Input() content: object;
  @Input() colDefs: Array<{ type: string; field: string; headerName: string }>;
  @Input() mainFields: string[];
  @Input() rowNumber: number;
  @Input() total: number;
  @Input() showTotal: boolean;
  @Input() showFooter: boolean;
  @Input() paging: PagingModel;
  @Output() actionClick = new EventEmitter();
  @Output() pagingChange = new EventEmitter();

  constructor(private datePipe: DatePipe, private translate: TranslatePipe, private cellRendererPipe: CellRendererPipe) {
    this.mainData = []; this.extraData = []; this.selectItems = []; this.dropdownItems = []; this.colDefs = []; this.mainFields = [];
    this.showExtraData = false; this.isShadow = false; this.showFooter = false;
    this.page = 1;
    this.showTotal = true;
  }

  ngOnInit(): void {
    this.colDefs.forEach((item) => {
      if ((item.field === 'status' && item.type !== 'select') || item.type === 'status') {
        this.setStatus(item);
        return;
      }
      else if (item.type === 'link') {
        this.setLink(item);
        return;
      }
      else if (item.type === 'select') {
        item['cellRendererParams']({ data: this.content }).dropdownItems;
        this.selectItems = this.content['dropdownItems'];
        return;
      }
      else if (item.field === 'action') {
        this.setDropdown(item);
        return;
      }
      if (item.field === '#' || item.headerName === '#' || !item.headerName) {
        return;
      }
      let classes = '';
      if (item.field && item.field.toLowerCase().includes('date')) {
        this.content[item.field] = this.datePipe.transform(this.content[item.field], 'YYYY-MM-dd');
        classes = 'd-inline-block ltr';
      }
      let temp = this.mainFields.length && this.mainFields.includes(item.field) ? 'mainData' : 'extraData';
      const cField = item.field ? item.field.toLowerCase() : '';
      const cHeaderName = item.headerName ? item.headerName.toLowerCase() : '';
      item.headerName = this.translate.transform(item.headerName);
      const conditionIQD = (cField.includes('amount') || cHeaderName.includes('amount') || (cField.includes('total') && item['cellRenderer'])) && !cHeaderName.includes('_ar') && !cHeaderName.includes(this.translate.transform('IQD'));
      item.headerName += conditionIQD ? (' (' + this.translate.transform('IQD') + ')') : '';
      if (item.field === 'check' || item.type === 'check') {
        this[temp].push({ field: 'check', title: item.headerName, text: this.content[item.field], class: classes });
      }
      else if (item['editable']) {
        this[temp].push({ type: 'editable', field: item.field, title: item.headerName });
      }
      else if (item.field !== 'status' && item.type !== 'status' && item.field !== 'link' && item.type !== 'link' && item.field !== 'select' && item.type !== 'select') {
        this[temp].push({
          title: item.headerName,
          text: item['cellRenderer'] && (item.field && !item.field.toLowerCase().includes('date')) ? this.translate.transform(this.cellRendererPipe.transform(item, { data: this.content }, item.field)) : this.content[item.field],
          class: classes
        });
      }
    });
  }

  setStatus(renderer: any): void {
    if (renderer.type !== 'template' && Object.getPrototypeOf(renderer['cellRenderer']) === Object.getPrototypeOf(StatusRenderer)) {
      const temp = renderer['cellRendererParams']({ data: this.content });
      const theme = temp.data.theme || temp.data.statusTheme;
      this.status = `<div class='theme ${ theme } align-items-center justify-content-center d-flex pt-1 pb-2 px-3'> ${ this.translate.transform(temp.data.statusName) } </div>`;
    }
    else {
      this.status = renderer['cellRenderer']({ data: this.content });
    }
  }

  setLink(renderer: any): void {
    let temp = this.mainFields.length && this.mainFields.includes(renderer.field) ? 'mainData' : 'extraData';
    this[temp].push({ field: 'link', title: null, text: null });
    if (renderer.type !== 'template' && Object.getPrototypeOf(renderer['cellRenderer']) === Object.getPrototypeOf(LinkRenderer)) {
      const temp = renderer['cellRendererParams']({ data: this.content });
      this.link = `<span class="fw-bold"> ${ renderer['headerName'] } </span> :
        ${ this.content[renderer['field']] ? `<a href="${ temp.url }" target="_blank">
          <i class="bx bx-link"> </i>
          <span> ${ this.content[renderer['field']] || temp.text } </span>
      </a>` : '' }`;
    }
    else {
      this.link = renderer['cellRenderer']({ data: this.content });
    }
  }

  setDropdown(renderer: any) {
    if (renderer.cellRendererParams.dropdownItems) {
      this.dropdownItems = renderer.cellRendererParams.dropdownItems;
      return;
    }
    const temp = renderer['cellRendererParams']({ data: this.content });
    this.dropdownItems = temp.dropdownItems || temp;
  }

  onDropdownItemClick(item: string): void {
    this.actionClick.emit({ row: this.content, action: item.toLowerCase() });
  }

  onChangePaging(event: any): void {
    this.page = event;
    this.paging.skip = (event - 1) * this.paging.take;
    this.pagingChange.emit(this.paging);
  }

}
