<section [id]="id" class="modal-body justify-content-center gap-3 flex-column d-flex">
  <h4 class="text-center">
    {{ title | translate }}
    <ng-container *ngIf="isQuestion && !isInfo"> {{ (title | translate).indexOf('?') === -1 && (title | translate).indexOf('؟') === -1 ? '؟' : '' }} </ng-container>
  </h4>
  <h5 *ngIf="text" class="text-center"> {{ text | translate }} </h5>
  <h5 *ngIf="text2" class="text-center"> {{ text2 | translate }} </h5>
  <div class="justify-content-center gap-3 d-flex mt-4">
    <app-btn-custom *ngIf="!isInfo" (click)="confirm.emit()" [theme]="{ type: 'fill', color: color, text: primaryText }" class="flex-fill" classList="w-100 py-2"> </app-btn-custom>
    <app-btn-custom (click)="modalRef ? modalRef.dismiss() : modalService.dismissAll()" [theme]="{ type: 'solid', color: secondColor, text: secondText }" class="flex-fill" classList="w-100 py-2"> </app-btn-custom>
  </div>
</section>
